.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.formCustom > .MuiStack-root {
  margin-left: 0vw;
  min-width: "100%";
}

.listItem > span {
  font-family: "Lora";
}

.listItem > .MuiTypography-root {
  font-family: "Lora";
}

.formCustom > .MuiStack-root {
  margin-top: 0vw !important;
  padding-top: 0% !important;
}

.formCustom {
  margin-top: 30px !important;
  padding-top: 0% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .renderSlide {
  }

  .resFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: "100%";
  }

  .formCustom > .MuiStack-root {
    margin-left: 0vw;
    min-width: 87vw;
    margin-top: 5vw !important;
    padding-top: 0% !important;
  }

  .formCustom {
    margin-top: 0px !important;
    padding-top: 0% !important;
  }

  .web-dis {
    display: none;
  }
}
